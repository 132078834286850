// Custom.scss
$body-bg: #081D35;
$body-color: #dcdcd2;

$body-secondary-bg: #273649;

$body-tertiary-bg: #738794;
$body-tertiary-color: #fdfdfd;

$body-emphasis-color: #fdfdfd;

$border-color: #27354A;

$text-dark: #0C0C17;
$text-tertiary: #fdfdfd;

// Fonts
$font-family-sans-serif: Menlo, Monaco, "Courier New", monospace;
$font-family-monospace: Menlo, Monaco, "Courier New", monospace;

$font-size-lg: 1.5rem;
$nav-link-font-size: 1.15rem;

$primary: #e49e4c;
$secondary: #5db490;
$light: $body-color;
$success: #198754;
$info: #56c1d6;
$warning: #ffc107;
$danger: #d14444;

// Navbar Colors
$navbar-dark-color: $primary;
$navbar-dark-hover-color: $secondary;
$navbar-dark-active-color: $primary;
// $navbar-dark-disabled-color: white;

$dropdown-link-hover-bg: #738794;
$list-group-active-bg: $body-tertiary-bg;


$input-bg: #273649;

$enable-negative-margins: true;

$offcanvas-vertical-height: 65vh;
// Offcanvas Color
// $modal-content-bg: $body-bg;
// $modal-content-color: $secondary; // Offcanvas Title

$toast-color: #343a40;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": #738794,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": #081D35,
  "card": #273649,
);

@import "../../../node_modules/bootstrap/scss/bootstrap";

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: #{$value};
  }
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.social {
  justify-content: flex-start;
  flex-direction: row;
}

// Custom CSS
.section {
  height: 100vh;
  align-items: center;
}

.d-flex-ui {
  display: flex;
}

$dark-bg: #37455a;

.graphic {
  background-image: url("../img/Desk.svg"); /* The image used */
  background-color: $body-bg; /* Used if the image is unavailable */
  width: 100%; /* You must set a specified height */
  height: 0;
  padding-top: 70%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.row {
  --bs-gutter-x: 0;
}

.toast {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.border-dashed {
  border-style: dashed !important;
}

.lh-xl {
  line-height: 2.5;
}

$widths: 65, 150, 300, 500; 
@each $width in $widths {
  .min-w-#{$width}px {
    min-width: #{$width}px;
  }
}

$heights: 150, 350, 400, 500;
@each $height in $heights {
  .min-h-#{$height}px {
    min-height: #{$height}px;
  }
  .max-h-#{$height}px {
    max-height: #{$height}px;
  }
}

$maxHeights: 50;
@each $height in $maxHeights {
  .max-h-#{$height} {
    max-height: '#{$height}%';
  }
}

$rem-widths: 2;
@each $width in $rem-widths {
  .min-w-#{$width}rem {
    min-width: #{$width}rem;
  }
}

$indices: 1, 2, 3, 4, 5, 1070 ;
@each $index in $indices {
  .z-index-#{$index} {
    z-index: #{$index};
  }
}

// SM
@media (min-width: 576px) {
}

// MD
@media (min-width: 768px) {
}

// LG
@media (min-width: 992px) {
}
