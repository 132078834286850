/* THREE-DIMENSIONAL LETTERS */

.threed {
  text-transform: uppercase;
  font-family: verdana;
  font-size: 8em;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
}

.threed-sm {
  font-size: 4em !important;
}

/* FORM STYLES */

.form-control {
  border: 0;
}

.input-group {
  border-radius: var(--bs-border-radius);
}

.input-group, .form-control:not(.input-group > .form-control):not(:focus) {
  box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.2);
}

.input-group:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(228, 158, 76, 0.25);
}

.password-input-group > .form-control:focus {
  box-shadow: none;
}

.password-input-group > .btn {
  background-color: var(--bg-color);
  color: var(--bs-color);
  border: 0;
}

.password-input-group > .btn:hover {
  background-color: var(--bg-color);
  color: var(--bs-secondary);
}

/* UTILS */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-shadow, .no-shadow:focus {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.h-fit-content {
  height: fit-content;
}

/* CLOUD ANIMATIONS */

.cloud {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

#cloud1 {
  animation: bobble 2s infinite;
}
#cloud2 {
  animation: bobble 3s infinite;
}
#cloud3 {
  animation: bobble 2.5s infinite;
}

@keyframes bobble {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translate3d(0px, 8px, 0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

.hover:hover {
  transform: translateY(-25px) scale(2);
  transition-duration: 1s;
  box-shadow: 0 0 1rem 0.25rem rgba(228, 158, 76, 0.25);
}

/* BLINKING */
.blink {
  font-weight: 100;
  font-size: 22px;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #dcdcd2;
  }
}

/* ROAST BUTTONS */
.roast-btn {
  width: 30%;
  aspect-ratio: 1;
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  font-weight: bold;
}